// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte';
@import '~@splidejs/splide/dist/css/splide.min.css';

#site-logo {
    max-width: 220px;
}

#mainContent article, .company-banner {
    background-color: #FFF !important;
}

.hr-divider {
    border: solid #007BFF 1px;
}

.f-size-30 {
    font-size: 30px;
}

.f-size-14 {
    font-size: 14px;
}

.f-size-20 {
    font-size: 20px;
}

.f-size-24 {
    font-size: 24px;
}

.region-image {
    width: 100%;
    height: 20vh;
    object-fit: cover;
}

.banner-mini {
    width: 240px;
    height: 120px;
    object-fit: cover;
    transition: .2s;
}

.banner-mini:hover {
    cursor: pointer;
    opacity: .5;
}

.img-mini {
    width: 120px;
    height: 120px;
    object-fit: cover;
    transition: .2s;
}

.img-mini:hover {
    cursor: pointer;
    opacity: .5;
}

.img-modal {
    max-height: 400px;
    object-fit: cover;
}

.img-company {
    max-height: 170px;
    width: 200px;
    border: 1px #AAA solid;
    border-radius: 3px;
}

.img-announcement {
    width: 340px;
    height: 250px;
    object-fit: cover;
}

/* Boxes */
.box {
    padding: 1rem;
    margin: .25rem;
    transition: .2s;
}

.box:hover {
    background: #ACf2f3;
    box-shadow: 0 0 5px 0 #B6C6C3;
}

.box-mini {
    background-color: #359BED;
    margin: 0 5px 0 0;
    padding: .1rem .3rem;
    color: #FFF;
    float: left;
    transition: .2s;
}

.box-mini:hover {
    background-color: #248ADC;
    box-shadow: 0 0 3px 0 #1379CB;
}

/* Input file */

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: #21c463;
    display: inline-block;
    padding: 10px;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #11b352;
}

.inputfile + label {
    cursor: pointer; /* "hand" cursor */
}

/* Cards */

.card-map .card-body {
    height: 120px;
}

.card-map .card-footer {
    height: 70px;
}

.card-map h5 {
    font-size: 1rem;
}

.module-border {
    border: 1px #009DFF solid;
    border-radius: 5px;
}

.outline-red {
    outline: #dc3545;
}

.selected {
    background: #3B4F6A;
    color: #FFF;
    padding: 5px;
    box-shadow: 0 0 5px 0 #3b4f6a;
}

.not-selected {
    color: #3b4f6a;
    padding: 5px;
    border: 1px solid #3b4f6a;
}

/* Media */
@media screen and (max-width: 430px) {
    iframe {
        width: 200px;
    }
}
